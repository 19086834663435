/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EventTestimonial_event_read } from './EventTestimonial_event_read';
import type { MediaObject_event_read } from './MediaObject_event_read';

export type Event_event_read = {
    readonly id?: number;
    name?: string;
    description?: string;
    image?: MediaObject_event_read;
    gallery?: Array<MediaObject_event_read>;
    date?: string;
    type?: Event_event_read.type;
    testimonials?: Array<EventTestimonial_event_read>;
    slug?: string;
    sortOrder?: number;
};

export namespace Event_event_read {

    export enum type {
        BIRTHDAY_PARTY = 'BirthdayParty',
        PROPOSAL = 'Proposal',
        BACHELOR_PARTY = 'BachelorParty',
        CORPORATE_EVENT = 'CorporateEvent',
        FAMILY_EVENT = 'FamilyEvent',
        OTHER = 'Other',
    }


}

