/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Address_jsonld_available_boat_group_read } from './Address_jsonld_available_boat_group_read';
import type { Coordinates_jsonld_available_boat_group_read } from './Coordinates_jsonld_available_boat_group_read';
import type { Info_jsonld_available_boat_group_read } from './Info_jsonld_available_boat_group_read';
import type { MediaObject_jsonld_available_boat_group_read } from './MediaObject_jsonld_available_boat_group_read';
import type { Restriction_jsonld_available_boat_group_read } from './Restriction_jsonld_available_boat_group_read';

export type Location_jsonld_available_boat_group_read = {
    readonly '@context'?: (string | {
        '@vocab': string;
        hydra: Location_jsonld_available_boat_group_read.hydra;
    });
    readonly '@id'?: string;
    readonly '@type'?: string;
    readonly id?: number;
    name?: string;
    address?: Address_jsonld_available_boat_group_read;
    coordinates?: Coordinates_jsonld_available_boat_group_read;
    mapImage?: MediaObject_jsonld_available_boat_group_read;
    info?: Info_jsonld_available_boat_group_read;
    restriction?: Restriction_jsonld_available_boat_group_read;
    description?: string;
    gallery?: Array<MediaObject_jsonld_available_boat_group_read>;
    slug?: string;
};

export namespace Location_jsonld_available_boat_group_read {

    export enum hydra {
        HTTP_WWW_W3_ORG_NS_HYDRA_CORE_ = 'http://www.w3.org/ns/hydra/core#',
    }


}

