/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BoatPassType_SortOrderDto_boat_pass_type_write = {
    direction: BoatPassType_SortOrderDto_boat_pass_type_write.direction | null;
};

export namespace BoatPassType_SortOrderDto_boat_pass_type_write {

    export enum direction {
        UP = 'up',
        DOWN = 'down',
    }


}

