/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BoatGroup_SortOrderDto_boat_group_write = {
    direction: BoatGroup_SortOrderDto_boat_group_write.direction | null;
};

export namespace BoatGroup_SortOrderDto_boat_group_write {

    export enum direction {
        UP = 'up',
        DOWN = 'down',
    }


}

