/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BoatGroup_boat_group_read } from '../models/BoatGroup_boat_group_read';
import type { BoatGroup_BoatGroupCruiseAddon_cruise_addon_read } from '../models/BoatGroup_BoatGroupCruiseAddon_cruise_addon_read';
import type { BoatGroup_BoatGroupDto_boat_group_write } from '../models/BoatGroup_BoatGroupDto_boat_group_write';
import type { BoatGroup_BoatGroupSettings } from '../models/BoatGroup_BoatGroupSettings';
import type { BoatGroup_client_boat_group_read } from '../models/BoatGroup_client_boat_group_read';
import type { BoatGroup_ShareCalendarDto_boat_group_write } from '../models/BoatGroup_ShareCalendarDto_boat_group_write';
import type { BoatGroup_SortOrderDto_boat_group_write } from '../models/BoatGroup_SortOrderDto_boat_group_write';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BoatGroupService {

    /**
     * Retrieves the collection of BoatGroup resources.
     * Retrieves the collection of BoatGroup resources.
     * @returns BoatGroup_boat_group_read BoatGroup collection
     * @throws ApiError
     */
    public static apiAdminboatGroupsGetCollection({
        page = 1,
        pagination,
        isPublicType,
        isPrivateType,
        orderSortOrder,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * Enable or disable pagination
         */
        pagination?: boolean,
        isPublicType?: boolean,
        isPrivateType?: boolean,
        orderSortOrder?: 'asc' | 'desc',
    }): CancelablePromise<Array<BoatGroup_boat_group_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/boat-groups',
            query: {
                'page': page,
                'pagination': pagination,
                'isPublicType': isPublicType,
                'isPrivateType': isPrivateType,
                'order[sortOrder]': orderSortOrder,
            },
        });
    }

    /**
     * Creates a BoatGroup resource.
     * Creates a BoatGroup resource.
     * @returns BoatGroup_boat_group_read BoatGroup resource created
     * @throws ApiError
     */
    public static apiAdminboatGroupsPost({
        requestBody,
    }: {
        /**
         * The new BoatGroup resource
         */
        requestBody: BoatGroup_BoatGroupDto_boat_group_write,
    }): CancelablePromise<BoatGroup_boat_group_read> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/boat-groups',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Retrieves a BoatGroup resource.
     * Retrieves a BoatGroup resource.
     * @returns BoatGroup_boat_group_read BoatGroup resource
     * @throws ApiError
     */
    public static apiAdminboatGroupsIdGet({
        id,
    }: {
        /**
         * BoatGroup identifier
         */
        id: string,
    }): CancelablePromise<BoatGroup_boat_group_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/boat-groups/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Replaces the BoatGroup resource.
     * Replaces the BoatGroup resource.
     * @returns BoatGroup_boat_group_read BoatGroup resource updated
     * @throws ApiError
     */
    public static apiAdminboatGroupsIdPut({
        id,
        requestBody,
    }: {
        /**
         * BoatGroup identifier
         */
        id: string,
        /**
         * The updated BoatGroup resource
         */
        requestBody: BoatGroup_BoatGroupDto_boat_group_write,
    }): CancelablePromise<BoatGroup_boat_group_read> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/boat-groups/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Removes the BoatGroup resource.
     * Removes the BoatGroup resource.
     * @returns void
     * @throws ApiError
     */
    public static apiAdminboatGroupsIdDelete({
        id,
    }: {
        /**
         * BoatGroup identifier
         */
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/admin/boat-groups/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Retrieves the collection of BoatGroup resources.
     * Retrieves the collection of BoatGroup resources.
     * @returns BoatGroup_BoatGroupCruiseAddon_cruise_addon_read BoatGroup collection
     * @throws ApiError
     */
    public static apiAdminboatGroupsIdcruiseAddonsGetCollection({
        id,
        type,
        length,
        startAt,
    }: {
        /**
         * BoatGroup identifier
         */
        id: string,
        /**
         * Type of cruise
         */
        type: string,
        /**
         * Length of cruise (in minutes)
         */
        length: number,
        /**
         * Cruise Start Time
         */
        startAt: string,
    }): CancelablePromise<Array<BoatGroup_BoatGroupCruiseAddon_cruise_addon_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/boat-groups/{id}/cruise-addons',
            path: {
                'id': id,
            },
            query: {
                'type': type,
                'length': length,
                'startAt': startAt,
            },
        });
    }

    /**
     * Creates a BoatGroup resource.
     * Creates a BoatGroup resource.
     * @returns void
     * @throws ApiError
     */
    public static apiAdminboatGroupsIdshareCalendarPost({
        id,
        requestBody,
    }: {
        /**
         * BoatGroup identifier
         */
        id: string,
        /**
         * The new BoatGroup resource
         */
        requestBody: BoatGroup_ShareCalendarDto_boat_group_write,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/boat-groups/{id}/share-calendar',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Creates a BoatGroup resource.
     * Creates a BoatGroup resource.
     * @returns void
     * @throws ApiError
     */
    public static apiAdminboatGroupsIdsortOrderPost({
        id,
        requestBody,
    }: {
        /**
         * BoatGroup identifier
         */
        id: string,
        /**
         * The new BoatGroup resource
         */
        requestBody: BoatGroup_SortOrderDto_boat_group_write,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/boat-groups/{id}/sort-order',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Retrieves a BoatGroup resource.
     * Retrieves a BoatGroup resource.
     * @returns BoatGroup_BoatGroupSettings BoatGroup resource
     * @throws ApiError
     */
    public static apiBoatGroupSettingsGet({
        type,
    }: {
        type: any,
    }): CancelablePromise<BoatGroup_BoatGroupSettings> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/boat-group-settings',
            query: {
                'type': type,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Retrieves the collection of BoatGroup resources.
     * Retrieves the collection of BoatGroup resources.
     * @returns BoatGroup_client_boat_group_read BoatGroup collection
     * @throws ApiError
     */
    public static apiClientboatGroupsGetCollection({
        page = 1,
        itemsPerPage = 30,
        pagination,
        isPublicType,
        isPrivateType,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        /**
         * The number of items per page
         */
        itemsPerPage?: number,
        /**
         * Enable or disable pagination
         */
        pagination?: boolean,
        isPublicType?: boolean,
        isPrivateType?: boolean,
    }): CancelablePromise<Array<BoatGroup_client_boat_group_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client/boat-groups',
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
                'pagination': pagination,
                'isPublicType': isPublicType,
                'isPrivateType': isPrivateType,
            },
        });
    }

    /**
     * Retrieves a BoatGroup resource.
     * Retrieves a BoatGroup resource.
     * @returns BoatGroup_boat_group_read BoatGroup resource
     * @throws ApiError
     */
    public static apiClientboatGroupsIdGet({
        id,
    }: {
        /**
         * BoatGroup identifier
         */
        id: string,
    }): CancelablePromise<BoatGroup_boat_group_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client/boat-groups/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Retrieves the collection of BoatGroup resources.
     * Retrieves the collection of BoatGroup resources.
     * @returns BoatGroup_BoatGroupCruiseAddon_cruise_addon_read BoatGroup collection
     * @throws ApiError
     */
    public static apiClientboatGroupsIdcruiseAddonsGetCollection({
        id,
        type,
        length,
        startAt,
    }: {
        /**
         * BoatGroup identifier
         */
        id: string,
        /**
         * Type of cruise
         */
        type: string,
        /**
         * Length of cruise (in minutes)
         */
        length: number,
        /**
         * Cruise Start Time
         */
        startAt: string,
    }): CancelablePromise<Array<BoatGroup_BoatGroupCruiseAddon_cruise_addon_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client/boat-groups/{id}/cruise-addons',
            path: {
                'id': id,
            },
            query: {
                'type': type,
                'length': length,
                'startAt': startAt,
            },
        });
    }

}
