/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BoatPassType_boat_pass_type_read } from '../models/BoatPassType_boat_pass_type_read';
import type { BoatPassType_BoatPassTypeDto_boat_pass_type_write } from '../models/BoatPassType_BoatPassTypeDto_boat_pass_type_write';
import type { BoatPassType_SortOrderDto_boat_pass_type_write } from '../models/BoatPassType_SortOrderDto_boat_pass_type_write';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BoatPassTypeService {

    /**
     * Retrieves the collection of BoatPassType resources.
     * Retrieves the collection of BoatPassType resources.
     * @returns BoatPassType_boat_pass_type_read BoatPassType collection
     * @throws ApiError
     */
    public static apiAdminboatPassTypesGetCollection({
        page = 1,
        orderSortOrder,
    }: {
        /**
         * The collection page number
         */
        page?: number,
        orderSortOrder?: 'asc' | 'desc',
    }): CancelablePromise<Array<BoatPassType_boat_pass_type_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/boat-pass-types',
            query: {
                'page': page,
                'order[sortOrder]': orderSortOrder,
            },
        });
    }

    /**
     * Creates a BoatPassType resource.
     * Creates a BoatPassType resource.
     * @returns BoatPassType_boat_pass_type_read BoatPassType resource created
     * @throws ApiError
     */
    public static apiAdminboatPassTypesPost({
        requestBody,
    }: {
        /**
         * The new BoatPassType resource
         */
        requestBody: BoatPassType_BoatPassTypeDto_boat_pass_type_write,
    }): CancelablePromise<BoatPassType_boat_pass_type_read> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/boat-pass-types',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Retrieves a BoatPassType resource.
     * Retrieves a BoatPassType resource.
     * @returns BoatPassType_boat_pass_type_read BoatPassType resource
     * @throws ApiError
     */
    public static apiAdminboatPassTypesIdGet({
        id,
    }: {
        /**
         * BoatPassType identifier
         */
        id: string,
    }): CancelablePromise<BoatPassType_boat_pass_type_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/admin/boat-pass-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Replaces the BoatPassType resource.
     * Replaces the BoatPassType resource.
     * @returns BoatPassType_boat_pass_type_read BoatPassType resource updated
     * @throws ApiError
     */
    public static apiAdminboatPassTypesIdPut({
        id,
        requestBody,
    }: {
        /**
         * BoatPassType identifier
         */
        id: string,
        /**
         * The updated BoatPassType resource
         */
        requestBody: BoatPassType_BoatPassTypeDto_boat_pass_type_write,
    }): CancelablePromise<BoatPassType_boat_pass_type_read> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/admin/boat-pass-types/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                404: `Resource not found`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Removes the BoatPassType resource.
     * Removes the BoatPassType resource.
     * @returns void
     * @throws ApiError
     */
    public static apiAdminboatPassTypesIdDelete({
        id,
    }: {
        /**
         * BoatPassType identifier
         */
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/admin/boat-pass-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

    /**
     * Creates a BoatPassType resource.
     * Creates a BoatPassType resource.
     * @returns void
     * @throws ApiError
     */
    public static apiAdminboatPassTypesIdsortOrderPost({
        id,
        requestBody,
    }: {
        /**
         * BoatPassType identifier
         */
        id: string,
        /**
         * The new BoatPassType resource
         */
        requestBody: BoatPassType_SortOrderDto_boat_pass_type_write,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/boat-pass-types/{id}/sort-order',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input`,
                422: `Unprocessable entity`,
            },
        });
    }

    /**
     * Retrieves the collection of BoatPassType resources.
     * Retrieves the collection of BoatPassType resources.
     * @returns BoatPassType_boat_pass_type_read BoatPassType collection
     * @throws ApiError
     */
    public static apiClientboatPassTypesGetCollection({
        page = 1,
    }: {
        /**
         * The collection page number
         */
        page?: number,
    }): CancelablePromise<Array<BoatPassType_boat_pass_type_read>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client/boat-pass-types',
            query: {
                'page': page,
            },
        });
    }

    /**
     * Retrieves a BoatPassType resource.
     * Retrieves a BoatPassType resource.
     * @returns BoatPassType_boat_pass_type_read BoatPassType resource
     * @throws ApiError
     */
    public static apiClientboatPassTypesIdGet({
        id,
    }: {
        /**
         * BoatPassType identifier
         */
        id: string,
    }): CancelablePromise<BoatPassType_boat_pass_type_read> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/client/boat-pass-types/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Resource not found`,
            },
        });
    }

}
