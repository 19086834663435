/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Event_SortOrderDto_event_write = {
    direction: Event_SortOrderDto_event_write.direction | null;
};

export namespace Event_SortOrderDto_event_write {

    export enum direction {
        UP = 'up',
        DOWN = 'down',
    }


}

